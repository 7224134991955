import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="has-background-grey-light">
          <div
            className="margin-top-0"
            style={{
              backgroundImage: `url('/img/background-4.jpg')`,
              backgroundPosition: "center",
              padding: "5rem",
            }}
          >
            <div className="py-6">
              <div className="columns is-multiline has-text-centered">
                <div className="column is-full is-flexible">
                  <h2 className="has-text-weight-bold is-size-1 has-text-white has-text-centered">
                    I want to:
                  </h2>
                </div>
                <div className="column is-4">
                  <Link
                    to="/apply-now/purchase-form"
                    className="box is-link"
                    style={{ minHeight: "8rem" }}
                  >
                    <h4 className="title is-4">Purchase</h4>
                    <h6 className="title is-6 has-text-grey-dark">
                      Purchase a home
                    </h6>
                  </Link>
                </div>
                <div className="column is-4">
                  <Link
                    to="/apply-now/refinance-form"
                    className="box is-link"
                    style={{ minHeight: "8rem" }}
                  >
                    <h4 className="title is-4">Refinance</h4>
                    <h6 className="title is-6 has-text-grey-dark">
                      Refinance a home I already own
                    </h6>
                  </Link>
                </div>
                <div className="column is-4">
                  <Link
                    to="/contact"
                    className="box is-link"
                    style={{ minHeight: "8rem" }}
                  >
                    <h4 className="title is-4">Talk</h4>
                    <h6 className="title is-6 has-text-grey-dark">
                      Request a phone call to discuss my options
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
